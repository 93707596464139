main.home {

    display: block;
    position: relative;

    section, 
    aside {
        @include sm-down {
            padding: 50px 0;
        }
        @include md-up {
            padding: 75px 0;
        }

        h2 strong {
            display: inline;

            color:  $yellow;
            background: $black;
    
            @include xs {
                padding:        3px 8px;
            }
    
            @include sm {
                padding:        3px 8px;
            }
    
            @include md {
                padding:        3px 8px;
            }
    
            @include lg {
                padding:        3px 10px;
            }
    
            @include xl {
                padding:        3px 15px;
            }
    
            box-decoration-break: clone;
            -webkit-box-decoration-break: clone;

        }

        .home--about--highlight-para span {
            display: inline;

            color:  $black;
            background: $yellow;
    
            @include xs {
                padding:        5px 8px;
            }
    
            @include sm {
                padding:        5px 8px;
            }
    
            @include md {
                padding:        5px 8px;
            }
    
            @include lg {
                padding:        8px 10px;
            }
    
            @include xl {
                padding:        8px 15px;
            }
    
            box-decoration-break: clone;
            -webkit-box-decoration-break: clone;
        }

        a {

            text-decoration: underline;

            &:hover,
            &:focus {

                color:  $white;
                background: $black;

                outline: 3px solid $black;

                text-decoration: none;
        
                box-decoration-break: clone;
                -webkit-box-decoration-break: clone;
            
            }

        }
    }
   
    section#home--jumbotron {
        background: $black;
        color:      $white;

        h1 {
            max-width: 25ch;

            font-weight: normal;

            strong {
                color: $yellow;

                font-weight: bold;
            }
        }

        p {
            max-width: 35ch;
        }
    }

    aside#home--subscribe,
    aside#home--be-a-guest,
    section#home--contact {
        background: $yellow;
        color:      $black;
        
        h2 strong {
            background:     $black;
            color:          $yellow;
        }

        p, h2 {
            @include md-up {
                max-width: 55ch;
            }
        }
    }

    section h1:first-child,
    aside h1:first-child,
    section h2:first-child,
    aside h2:first-child, {
        padding-top: 0;
    }

    section p:last-child,
    aside p:last-child {
        padding-bottom: 0;
    }

    section#home--loop {

        h2 {
            padding-bottom: 10px;
        }

        ul {
			list-style: none;
			padding: 			0;
			
			li.loop--item {
								
				padding: 		0;
				margin: 		0;
				
				border-bottom: 	1px solid $black;
				
				article.loop--item--content {
					
					display: 		inline-block;
					position: 		relative;
										
					h3.item--content--title {

                        font-size: 			$p-font-size-base;
                        line-height: 		$p-line-height-base;
                        padding-bottom: 	$p-padding-bottom;
                        
                        @include xs {
                            font-size: 		$p-font-size-base-xs;
                            line-height: 	$p-line-height-base-xs;
                            padding-bottom: 	$p-padding-bottom-xs;
                        }
                        @include sm {
                            font-size: 		$p-font-size-base-sm;
                            line-height: 	$p-line-height-base-sm;
                            padding-bottom: 	$p-padding-bottom-sm;
                        }
                        @include md {
                            font-size: 		$p-font-size-base-md;
                            line-height: 	$p-line-height-base-md;
                            padding-bottom: 	$p-padding-bottom-md;
                        }
                        @include lg {
                            font-size: 		$p-font-size-base-lg;
                            line-height: 	$p-line-height-base-lg;
                            padding-bottom: $p-padding-bottom-lg;
                        }
                        @include xl {
                            font-size: 		$p-font-size-base-xl;
                            line-height: 	$p-line-height-base-xl;
                            padding-bottom: $p-padding-bottom-xl;
                        }
                        
                        font-weight:			$p-font-weight;
						
					}
				}
            }
            
            p:last-child {
                
                font-size: 			$p-font-size-base * 0.75;
                line-height: 		$p-line-height-base;
                padding-top: 	$p-padding-bottom;
                
                @include xs {
                    font-size: 		$p-font-size-base-xs * 0.75;
                    line-height: 	$p-line-height-base-xs;
                    padding-top: 	$p-padding-bottom-xs;
                }
                @include sm {
                    font-size: 		$p-font-size-base-sm * 0.75;
                    line-height: 	$p-line-height-base-sm;
                    padding-top: 	$p-padding-bottom-sm;
                }
                @include md {
                    font-size: 		$p-font-size-base-md * 0.75;
                    line-height: 	$p-line-height-base-md;
                    padding-top: 	$p-padding-bottom-md;
                }
                @include lg {
                    font-size: 		$p-font-size-base-lg * 0.75;
                    line-height: 	$p-line-height-base-lg;
                    padding-top: $p-padding-bottom-lg;
                }
                @include xl {
                    font-size: 		$p-font-size-base-xl * 0.75;
                    line-height: 	$p-line-height-base-xl;
                    padding-top: $p-padding-bottom-xl;
                }

                a {
                    text-decoration: none;

                    &:hover,
                    &:focus {
                        color: $yellow;
                    }
                }

            }
		}
		

    }

}