main.post,
main.episode {

    aside#breadcrumbs {
        background: $yellow;

        p {
            @include xs {
                padding: 	$p-padding-bottom-xs 0;
            }
            @include sm {
                padding: 	$p-padding-bottom-sm 0;
            }
            @include md {
                padding: 	$p-padding-bottom-md 0;
            }
            @include lg {
                padding: $p-padding-bottom-lg 0;
            }
            @include xl {
                padding: $p-padding-bottom-xl 0;
            }
        }
    }

    article#post--content,
    article#episode--content {
        @include sm-down {
            padding: 30px 0 50px 0;
        }
        @include md-up {
            padding: 30px 0 75px 0;
        } 
    }

    h1 {
        font-size: 			$h1-font-size-base;
        line-height: 		$h1-line-height-base;
        padding-top: 	    $h1-padding-bottom;
        
        @include xs {
            font-size: 		$h1-font-size-base-xs;
            line-height: 	$h1-line-height-base-xs * 1.5;
            padding-top: 	$h1-padding-bottom-xs;
        }
        @include sm {
            font-size: 		$h1-font-size-base-sm;
            line-height: 	$h1-line-height-base-sm * 1.5;
            padding-top: 	$h1-padding-bottom-sm;
        }
        @include md {
            font-size: 		$h1-font-size-base-md * 1.25;
            line-height: 	$h1-line-height-base-md * 1.6;
            padding-top: 	$p-padding-bottom-md;
        }
        @include lg {
            font-size: 		$h1-font-size-base-lg * 1.25;
            line-height: 	$h1-line-height-base-lg * 1.6;
            padding-top:    $h1-padding-bottom-lg;
        }
        @include xl {
            font-size: 		$h1-font-size-base-xl * 1.25;
            line-height: 	$h1-line-height-base-xl * 1.6;
            padding-top:    $h1-padding-bottom-xl;
        }

        strong {
            display: inline;

            color:  $yellow;
            background: $black;
    
            @include xs {
                padding:        3px 8px;
            }
    
            @include sm {
                padding:        3px 8px;
            }
    
            @include md {
                padding:        3px 8px;
            }
    
            @include lg {
                padding:        3px 10px;
            }
    
            @include xl {
                padding:        3px 15px;
            }
    
            box-decoration-break: clone;
            -webkit-box-decoration-break: clone;

        }

    }

    p {
        max-width: 60ch;
    }

    aside#post--metadata,
    aside#episode--content--metadata {
        @include sm-down {
            padding: 30px 0 50px 0;
        }
        @include md-up {
            padding: 50px 0 0 0;
        } 
        
        border-top: 3px solid $yellow;

        strong {
            display: inline;

            color:  $black;
            background: $yellow;
    
            @include xs {
                padding:        3px 8px;
            }
    
            @include sm {
                padding:        3px 8px;
            }
    
            @include md {
                padding:        3px 8px;
            }
    
            @include lg {
                padding:        3px 10px;
            }
    
            @include xl {
                padding:        3px 8px;
            }
    
            margin-right:       25px;
            
            box-decoration-break: clone;
            -webkit-box-decoration-break: clone;
        }

        a {
			
			display: inline-block;
			
			margin:			2rem 0 0 0;
			padding: 		5px 10px;
			
			border:			1px solid $black;
			
			text-decoration: 	none;
			
			&:hover,
			&:focus {
				background: 		$black;
				color: 				$yellow;
				
				text-decoration: 	none;
			}
			
		}
    }

    aside#post--next-prev {

        position: relative;

        @include md-up {
        display: flex;
        }

        margin-top: 30px;
        
        border-top: 3px solid $yellow;

        @include clearfix;

        p[class^="page--next-prev--"] {

            position: relative;
            flex: 1;
            
            background: $yellow;

            padding: 20px;
            margin: 20px 0;

            @include md-up {

                &:first-child {
                    margin-right: 10px;
                }
                &:last-child {
                    margin-left: 10px;
                }
                &:only-child {
                    margin-left: 0;
                    margin-right: 0;
                }

            }

            @include md-up {
                width: 50%;
                max-width: 50%;
            }

            a {
                display: inline-block;
                margin-left: 35px;

                &:hover {
                    background: none;
                    outline: none;
                }

                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }

            &:hover,
            &:focus {
                color:      $white;
                background: $black;
                outline:    none;
            }

            @include clearfix;
        }

        p.page--next-prev--prev {
            @include md-up {
                float:      left;
            }
        }

        p.page--next-prev--next {
            @include md-up {
                float:      right;
            }
        }

        p.page--next-prev--prev-blank {
            background: none;

            &:hover {
                background: none;
            }

            @include sm-down {
                display: none;
            }
        }

        span.page--next-prev--arrow {
            display: block;
            float: left;
            width: 35px;
        }

        span.page--next-prev--label {
            display: block;
            font-weight: bold;
        }

    }


    div.footnotes {
		
        &:before {
            
            display: block;
            
            @include xs {
                font-size: 		$h3-font-size-base-xs;
                line-height: 	$h3-line-height-base-xs;
                padding-top: 		$h3-padding-top-xs;
                padding-bottom: 	$h3-padding-bottom-xs;
            }
            @include sm {
                font-size: 		$h3-font-size-base-sm;
                line-height: 	$h3-line-height-base-sm;
                padding-top: 		$h3-padding-top-sm;
                padding-bottom: 	$h3-padding-bottom-sm;
            }
            @include md {
                font-size: 		$h3-font-size-base-md;
                line-height: 	$h3-line-height-base-md;
                padding-top: 		$h3-padding-top-md;
                padding-bottom: 	$h3-padding-bottom-md;
            }
            @include lg {
                font-size: 		$h3-font-size-base-lg;
                line-height: 	$h3-line-height-base-lg;
                padding-top: 		$h3-padding-top-lg;
                padding-bottom: 	$h3-padding-bottom-lg;
            }
            @include xl {
                font-size: 		$h3-font-size-base-xl;
                line-height: 	$h3-line-height-base-xl;
                padding-top: 		$h3-padding-top-xl;
                padding-bottom: 	$h3-padding-bottom-xl;
            }
            
            font-weight:			$h3-font-weight;
            font-style: 			$h3-font-style; 
            text-align: 			$h3-text-align;		
            text-decoration: 	$h3-text-decoration;
            text-transform: 		$h3-text-transform;
            
            content: "Footnotes";
        }
        
        padding-top: 			$footnotes-font-size-base * 6;
        max-width: 			600px;
        
        @include xs {
            padding-top: 		$footnotes-font-size-base-xs * 6;
            max-width: 		400px;
        }
        @include sm {
            padding-top: 		$footnotes-font-size-base-sm * 6;
            max-width: 		500px;
        }
        @include md {
            padding-top: 		$footnotes-font-size-base-md * 6;
            max-width: 		450px;
        }
        @include lg {
            padding-top: 		$footnotes-font-size-base-lg * 6;
            max-width: 		600px;
        }
        @include xl {
            padding-top: 		$footnotes-font-size-base-xl * 6;
            max-width: 		600px;
        }
        
        ul,ol {
            padding-left: $footnotes-font-size-base * 2;
        }
        
        p, li {
            font-size: 			$footnotes-font-size-base;
            line-height: 		$footnotes-line-height-base;
            padding-bottom: 		$footnotes-padding-bottom;
            
            @include xs {
                font-size: 		$footnotes-font-size-base-xs;
                line-height: 	$footnotes-line-height-base-xs;
                padding-bottom: 	$footnotes-padding-bottom-xs;
            }
            @include sm {
                font-size: 		$footnotes-font-size-base-sm;
                line-height: 	$footnotes-line-height-base-sm;
                padding-bottom: 	$footnotes-padding-bottom-sm;
            }
            @include md {
                font-size: 		$footnotes-font-size-base-md;
                line-height: 	$footnotes-line-height-base-md;
                padding-bottom: 	$footnotes-padding-bottom-md;
            }
            @include lg {
                font-size: 		$footnotes-font-size-base-lg;
                line-height: 	$footnotes-line-height-base-lg;
                padding-bottom: 	$footnotes-padding-bottom-lg;
            }
            @include xl {
                font-size: 		$footnotes-font-size-base-xl;
                line-height: 	$footnotes-line-height-base-xl;
                padding-bottom: 	$footnotes-padding-bottom-xl;
            }
            
            font-weight:			$footnotes-font-weight;
        }
        
    }

}