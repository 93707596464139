// Typography

hr {
	border:		none;

	height: 	3px;
	background: $yellow;

	margin: 	30px 0 45px 0;
}

a {
	
	display: 				inline;
	
	color: 					$link-color;
	text-decoration: 		$link-text-decoration;
	text-decoration-skip: 	ink;
	text-decoration-skip-ink: auto;
	
	outline: 				$link-outline;
	font-weight: 			inherit;
	
	
	/* Text and background color for dark mode */
	
	
	&:hover,
	&:focus {
		color: 					$link-hover-color;
		background: 			$black;
		
		text-decoration: 		$link-text-decoration-hover;
		text-decoration-skip: 	ink;
		text-decoration-skip-ink: auto;

		outline: 3px solid $black;

		text-decoration: none;

		box-decoration-break: clone;
		-webkit-box-decoration-break: clone;
	
	}
	
}

h1 {
	
	@include xs {
		font-size: 		$h1-font-size-base-xs;
		line-height: 	$h1-line-height-base-xs;
		padding-top: 	$h1-padding-top-xs;
		padding-bottom: $h1-padding-bottom-xs;
	}
	@include sm {
		font-size: 		$h1-font-size-base-sm;
		line-height: 	$h1-line-height-base-sm;
		padding-top: 	$h1-padding-top-sm;
		padding-bottom: 	$h1-padding-bottom-sm;
	}
	@include md {
		font-size: 		$h1-font-size-base-md;
		line-height: 	$h1-line-height-base-md;
		padding-top: 	$h1-padding-top-md;
		padding-bottom: 	$h1-padding-bottom-md;
	}
	@include lg {
		font-size: 		$h1-font-size-base-lg;
		line-height: 	$h1-line-height-base-lg;
		padding-top: 	$h1-padding-top-lg;
		padding-bottom: 	$h1-padding-bottom-lg;
	}
	@include xl {
		font-size: 		$h1-font-size-base-xl;
		line-height: 	$h1-line-height-base-xl;
		padding-top: 	$h1-padding-top-xl;
		padding-bottom: 	$h1-padding-bottom-xl;
	}
	
	font-weight:			$h1-font-weight;
	
}

h2 {
	
	@include xs {
		font-size: 		$h2-font-size-base-xs;
		line-height: 	$h2-line-height-base-xs;
		padding-top: 	$h2-padding-top-xs;
		padding-bottom: 	$h2-padding-bottom-xs;
	}
	@include sm {
		font-size: 		$h2-font-size-base-sm;
		line-height: 	$h2-line-height-base-sm;
		padding-top: 		$h2-padding-top-sm;
		padding-bottom: 	$h2-padding-bottom-sm;
	}
	@include md {
		font-size: 		$h2-font-size-base-md;
		line-height: 	$h2-line-height-base-md;
		padding-top: 		$h2-padding-top-md;
		padding-bottom: 	$h2-padding-bottom-md;
	}
	@include lg {
		font-size: 		$h2-font-size-base-lg;
		line-height: 	$h2-line-height-base-lg;
		padding-top: 		$h2-padding-top-lg;
		padding-bottom: 	$h2-padding-bottom-lg;
	}
	@include xl {
		font-size: 		$h2-font-size-base-xl;
		line-height: 	$h2-line-height-base-xl;
		padding-top: 		$h2-padding-top-xl;
		padding-bottom: 	$h2-padding-bottom-xl;
	}
	
	font-weight:			$h2-font-weight;
	font-style: 			$h2-font-style;
	text-align: 			$h2-text-align;
	text-decoration: 	$h2-text-decoration;
	text-transform: 		$h2-text-transform;
	
}

h3 {
	
	@include xs {
		font-size: 		$h3-font-size-base-xs;
		line-height: 	$h3-line-height-base-xs;
		padding-top: 		$h3-padding-top-xs;
		padding-bottom: 	$h3-padding-bottom-xs;
	}
	@include sm {
		font-size: 		$h3-font-size-base-sm;
		line-height: 	$h3-line-height-base-sm;
		padding-top: 		$h3-padding-top-sm;
		padding-bottom: 	$h3-padding-bottom-sm;
	}
	@include md {
		font-size: 		$h3-font-size-base-md;
		line-height: 	$h3-line-height-base-md;
		padding-top: 		$h3-padding-top-md;
		padding-bottom: 	$h3-padding-bottom-md;
	}
	@include lg {
		font-size: 		$h3-font-size-base-lg;
		line-height: 	$h3-line-height-base-lg;
		padding-top: 		$h3-padding-top-lg;
		padding-bottom: 	$h3-padding-bottom-lg;
	}
	@include xl {
		font-size: 		$h3-font-size-base-xl;
		line-height: 	$h3-line-height-base-xl;
		padding-top: 		$h3-padding-top-xl;
		padding-bottom: 	$h3-padding-bottom-xl;
	}
	
	font-weight:			$h3-font-weight;
	font-style: 			$h3-font-style; 
	text-align: 			$h3-text-align;		
	text-decoration: 	$h3-text-decoration;
	text-transform: 		$h3-text-transform;
		
}

h2 + h3 {
	
	@include xs {
		padding-top: 0 !important;
	}
	@include sm {
		padding-top: 0 !important;
	}
	@include md {
		padding-top: 0 !important;
	}
	@include lg {
		padding-top: 0 !important;
	}
	@include xl {
		padding-top: 0 !important;
	}

}

h4 {
	
	@include xs {
		font-size: 		$h4-font-size-base-xs;
		line-height: 	$h4-line-height-base-xs;
		padding-top: 		$h4-padding-top-xs;
		padding-bottom: 	$h4-padding-bottom-xs;
	}
	@include sm {
		font-size: 		$h4-font-size-base-sm;
		line-height: 	$h4-line-height-base-sm;
		padding-top: 		$h4-padding-top-sm;
		padding-bottom: 	$h4-padding-bottom-sm;
	}
	@include md {
		font-size: 		$h4-font-size-base-md;
		line-height: 	$h4-line-height-base-md;
		padding-top: 		$h4-padding-top-md;
		padding-bottom: 	$h4-padding-bottom-md;
	}
	@include lg {
		font-size: 		$h4-font-size-base-lg;
		line-height: 	$h4-line-height-base-lg;
		padding-top: 		$h4-padding-top-lg;
		padding-bottom: 	$h4-padding-bottom-lg;
	}
	@include xl {
		font-size: 		$h4-font-size-base-xl;
		line-height: 	$h4-line-height-base-xl;
		padding-top: 		$h4-padding-top-xl;
		padding-bottom: 	$h4-padding-bottom-xl;
	}
	
	font-weight:			$h4-font-weight;
	font-style: 			$h4-font-style; 
	text-align: 			$h4-text-align;		
	text-decoration: 	$h4-text-decoration;
	text-transform: 		$h4-text-transform;
	
}

h3 + h4 {
	
	margin: 0;
	
	@include xs {
		padding-top: 0 !important;
	}
	@include sm {
		padding-top: 0 !important;
	}
	@include md {
		padding-top: 0 !important;
	}
	@include lg {
		padding-top: 0 !important;
	}
	@include xl {
		padding-top: 0 !important;
	}
	
}

h5 {
	
	@include xs {
		font-size: 		$h5-font-size-base-xs;
		line-height: 	$h5-line-height-base-xs;
		padding-top: 		$h5-padding-bottom-xs;
		padding-bottom: 	$h5-padding-bottom-xs;
	}
	@include sm {
		font-size: 		$h5-font-size-base-sm;
		line-height: 	$h5-line-height-base-sm;
		padding-top: 		$h5-padding-bottom-sm;
		padding-bottom: 	$h5-padding-bottom-sm;
	}
	@include md {
		font-size: 		$h5-font-size-base-md;
		line-height: 	$h5-line-height-base-md;
		padding-top: 		$h5-padding-bottom-md;
		padding-bottom: 	$h5-padding-bottom-md;
	}
	@include lg {
		font-size: 		$h5-font-size-base-lg;
		line-height: 	$h5-line-height-base-lg;
		padding-top: 		$h5-padding-bottom-lg;
		padding-bottom: 	$h5-padding-bottom-lg;
	}
	@include xl {
		font-size: 		$h5-font-size-base-xl;
		line-height: 	$h5-line-height-base-xl;
		padding-top: 		$h5-padding-bottom-xl;
		padding-bottom: 	$h5-padding-bottom-xl;
	}
	
	font-weight:			$h5-font-weight;
	
}

p {
	
	font-size: 			$p-font-size-base;
	line-height: 		$p-line-height-base;
	padding-bottom: 	$p-padding-bottom;
	
	@include xs {
		font-size: 		$p-font-size-base-xs;
		line-height: 	$p-line-height-base-xs;
		padding-bottom: 	$p-padding-bottom-xs;
	}
	@include sm {
		font-size: 		$p-font-size-base-sm;
		line-height: 	$p-line-height-base-sm;
		padding-bottom: 	$p-padding-bottom-sm;
	}
	@include md {
		font-size: 		$p-font-size-base-md;
		line-height: 	$p-line-height-base-md;
		padding-bottom: 	$p-padding-bottom-md;
	}
	@include lg {
		font-size: 		$p-font-size-base-lg;
		line-height: 	$p-line-height-base-lg;
		padding-bottom: $p-padding-bottom-lg;
	}
	@include xl {
		font-size: 		$p-font-size-base-xl;
		line-height: 	$p-line-height-base-xl;
		padding-bottom: $p-padding-bottom-xl;
	}
	
	font-weight:			$p-font-weight;
	
	span.small-caps {
		font-variant: small-caps !important;
		font-size: 		120%;
	}
}

sup {
	font-feature-settings:		$sup-font-feature-settings;
    text-decoration: none;
    margin-left: 2px;
    
    a {
	    text-decoration: none;
	    
	    &:hover,
	    &:focus {
		    text-decoration: none;
	    }
    }
}
	
strong, b {
	font-weight: 	bold;
}

em, i {
	font-style: 	italic;
}

u {
	text-decoration: underline;
}

center {
	text-align: center;
}

li {
	
	font-size: 		$p-font-size-base;
	line-height: 	$p-line-height-base;
	
	@include xs {
		font-size: 		$p-font-size-base-xs;
		line-height: 	$p-line-height-base-xs;
		padding-bottom: 	$p-padding-bottom-xs;
	}
	@include sm {
		font-size: 		$p-font-size-base-sm;
		line-height: 	$p-line-height-base-sm;
		padding-bottom: 	$p-padding-bottom-sm;
	}
	@include md {
		font-size: 		$p-font-size-base-md;
		line-height: 	$p-line-height-base-md;
		padding-bottom: 	$p-padding-bottom-md;
	}
	@include lg {
		font-size: 		$p-font-size-base-lg;
		line-height: 	$p-line-height-base-lg;
		padding-bottom: $p-padding-bottom-lg;
	}
	@include xl {
		font-size: 		$p-font-size-base-xl;
		line-height: 	$p-line-height-base-xl;
		padding-bottom: $p-padding-bottom-xl;
	}
	
	font-weight:		$p-font-weight;
	
}

ul {
	
	list-style-type: disc;
	
	padding-left: $p-font-size-base * 3;
	
	@include xs {
		padding-top: 		$p-font-size-base-xs;
		padding-bottom: 	$p-font-size-base-xs * 2;
	}
	
	@include sm {
		padding-top: 		$p-font-size-base-sm;
		padding-bottom: 	$p-font-size-base-sm * 2;
	}
	
	@include md {
		padding-top: 		$p-font-size-base-md;
		padding-bottom: 	$p-font-size-base-md * 2;
	}
	
	@include lg {
		padding-top: 		$p-font-size-base-lg;
		padding-bottom: 	$p-font-size-base-lg * 2;
	}
	
	@include xl {
		padding-top: 		$p-font-size-base-xl;
		padding-bottom: 	$p-font-size-base-xl * 2;
	}

}

ol {
	
	padding-left: $p-font-size-base * 3;
	
	@include xs {
		padding-top: 		$p-font-size-base-xs;
		padding-bottom: 	$p-font-size-base-xs * 2;
	}
	
	@include sm {
		padding-top: 		$p-font-size-base-sm;
		padding-bottom: 	$p-font-size-base-sm * 2;
	}
	
	@include md {
		padding-top: 		$p-font-size-base-md;
		padding-bottom: 	$p-font-size-base-md * 2;
	}
	
	@include lg {
		padding-top: 		$p-font-size-base-lg;
		padding-bottom: 	$p-font-size-base-lg * 2;
	}
	
	@include xl {
		padding-top: 		$p-font-size-base-xl;
		padding-bottom: 	$p-font-size-base-xl * 2;
	}
		
	list-style: decimal;
}

blockquote {
			
	background: 			$blockquote-background;
	
	/* Text and background color for dark mode */
	
	
	padding: 			20px;
	
	margin-top: 		0;
	    
    @include xs {	    
		margin-bottom: 	$p-margin-bottom-xs * 1.5;
	}
	@include sm {	    
		margin-bottom: 	$p-margin-bottom-sm * 1.5;
	}
	@include md {	    
		margin-bottom: 	$p-margin-bottom-md * 1.5;
	}
	@include lg {	    
		margin-bottom: 	$p-margin-bottom-lg * 1.5;
	}
	@include xl {	    
		margin-bottom: 	$p-margin-bottom-xl * 1.5;
	}
			
	
	p {		
		&:first-child {
			padding-top: 0;
		}
		&:last-child {
			padding-bottom: 0;
		}
	}
	
	ul {	
		padding-left: $p-font-size-base * 1.5;
	}
	
	ol {		
		padding-left: $p-font-size-base * 2.5;
		
		&:last-child {
			padding-bottom: 0;
		}
		
	}
	
	p ~ ol, 
	p ~ ul {
		margin-top: -1 * $p-font-size-base;
		padding-bottom: 0.5 * $p-font-size-base;
	}
	
}

table {
	width: 100%;
		
	@include xs {
		font-size: 		$p-font-size-base-xs;
		line-height: 	$p-line-height-base-xs;
		margin-bottom: 	$p-margin-bottom-xs;
	}
	@include sm {
		font-size: 		$p-font-size-base-sm;
		line-height: 	$p-line-height-base-sm;
		margin-bottom: 	$p-margin-bottom-sm;
	}
	@include md {
		font-size: 		$p-font-size-base-md;
		line-height: 	$p-line-height-base-md;
		margin-bottom: 	$p-margin-bottom-md;
	}
	@include lg {
		font-size: 		$p-font-size-base-lg;
		line-height: 	$p-line-height-base-lg;
		margin-bottom: 	$p-margin-bottom-lg;
	}
	@include xl {
		font-size: 		$p-font-size-base-xl;
		line-height: 	$p-line-height-base-xl;
		margin-bottom: 	$p-margin-bottom-xl;
	}
	
    border-collapse: collapse;

    margin-bottom: 20px;
	     	
	thead {
		@extend strong;
		text-align: left;
		border-bottom: 1px solid $black;
		/* Text and background color for dark mode */
					
	}
	
	tfoot {
		@extend strong;
		text-align: left;
		border-top: 1px solid $black;
		/* Text and background color for dark mode */
		
	}
	
	tr {
		border-bottom: 1px solid $gray-200;

		&:last-child {
			border-bottom: none !important;
		}
	}
	
	th, td {
    	padding: 5px 10px;
		vertical-align: top;
		
		border-left: 1px solid $gray-200;
		
		
		&:first-child {
			border-left: none;
		}
	}
}

a.reversefootnote {
	text-decoration: none;
	font-family: $font-family-sans-serif !important;
}