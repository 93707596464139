// breakpoints

$xs:    0;
$sm:    600px;
$md:    766px;
$lg:    1000px;
$xl: 	1200px;

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-xs: 	 					300px;
$container-sm: 	 					560px;
$container-md: 	 					660px;
$container-lg: 	 					900px;
$container-xl: 	 					1100px;

$container-padding: 	 			15px;

// colours

$white:    #ffffff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000000 !default;

$blue:    #007aff !default;
$indigo:  #1d62f0 !default;
$purple:  #c643fc !default;
$pink:    #ff2a68 !default;
$red:     #FF1300 !default;
$orange:  #ff5e3a !default;
$yellow:  #FFD700 !default;
$green:   #0bd318 !default;
$cyan:    #1ad6fd !default;

// fonts

$font-family-sans-serif:      'Bitter', -apple-system, "Helvetica Neue", "Helvetica", BlinkMacSystemFont, Roboto, "Segoe UI", Arial, sans-serif !default;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:            $font-family-sans-serif !default;

$font-feature-settings:				"ss02", "tnum", "liga", "zero", "kern";
$sup-font-feature-settings:			"ss02", "tnum", "liga", "zero", "kern", "frac";

// links

$link-text-decoration:				underline;
$link-text-decoration-hover:		underline;
$link-outline:						none;

// Default colors

$body-background:					    $white;
$body-color:							$black;

$skiplink-color:						$white;
$skiplink-background:				    $cyan;

$link-color:						    $black;
$link-background:			            $yellow;
$link-hover-color:		                $white;
$link-hover-background:		            $black;
$link-font-weight:					    normal;
$link-outline:						    none;
$link-text-decoration:				    underline;
$link-text-decoration-hover:			underline;

$heading-color:			$black;

$code-color:			$black;

$footer-color:						    $body-color;
$footer-color-hover:					$link-hover-color;

$blockquote-code-color:					$black;

$blockquote-background:					$gray-200;

// DEFAULT TEXT SIZES & SPACING

$p-font-size-base:					18px;
$p-font-size-base-xs:				$p-font-size-base;
$p-font-size-base-sm:				18px;
$p-font-size-base-md:				18px;
$p-font-size-base-lg:				22px;
$p-font-size-base-xl:				26px;

$p-line-height-base:					$p-font-size-base + ($p-font-size-base / 3);
$p-line-height-base-xs:				$p-line-height-base;
$p-line-height-base-sm:				$p-font-size-base-sm + ($p-font-size-base-sm / 3);
$p-line-height-base-md:				$p-font-size-base-md + ($p-font-size-base-md / 3);
$p-line-height-base-lg:				$p-font-size-base-lg + ($p-font-size-base-lg / 3);
$p-line-height-base-xl:				$p-font-size-base-xl + ($p-font-size-base-xl / 3);

$p-margin-top:						$p-font-size-base;
$p-margin-top-xs:					$p-font-size-base-xs;
$p-margin-top-sm:					$p-font-size-base-sm;
$p-margin-top-md:					$p-font-size-base-md;
$p-margin-top-lg:					$p-font-size-base-lg;
$p-margin-top-xl:					$p-font-size-base-xl;

$p-margin-bottom:					$p-font-size-base;
$p-margin-bottom-xs:					$p-font-size-base-xs;
$p-margin-bottom-sm:					$p-font-size-base-sm;
$p-margin-bottom-md:					$p-font-size-base-md;
$p-margin-bottom-lg:					$p-font-size-base-lg;
$p-margin-bottom-xl:					$p-font-size-base-xl;

$p-padding-top:						$p-font-size-base * 0.75;
$p-padding-top-xs:					$p-font-size-base-xs * 0.75;
$p-padding-top-sm:					$p-font-size-base-sm * 0.75;
$p-padding-top-md:					$p-font-size-base-md * 0.75;
$p-padding-top-lg:					$p-font-size-base-lg * 0.75;
$p-padding-top-xl:					$p-font-size-base-xl * 0.75;

$p-padding-bottom:					$p-font-size-base * 0.75;
$p-padding-bottom-xs:					$p-font-size-base-xs * 0.75;
$p-padding-bottom-sm:					$p-font-size-base-sm * 0.75;
$p-padding-bottom-md:					$p-font-size-base-md * 0.75;
$p-padding-bottom-lg:					$p-font-size-base-lg * 0.75;
$p-padding-bottom-xl:					$p-font-size-base-xl * 0.75;

$p-font-weight:						normal;

// FOOT NOTES TEXT SIZES & SPACING

$footnotes-font-size-base:				16px;
$footnotes-font-size-base-xs:				$footnotes-font-size-base;
$footnotes-font-size-base-sm:				18px;
$footnotes-font-size-base-md:				18px;
$footnotes-font-size-base-lg:				20px;
$footnotes-font-size-base-xl:				22px;

$footnotes-line-height-base:				$footnotes-font-size-base + ($footnotes-font-size-base / 4);
$footnotes-line-height-base-xs:			$footnotes-line-height-base;
$footnotes-line-height-base-sm:			$footnotes-font-size-base-sm + ($footnotes-font-size-base-sm / 4);
$footnotes-line-height-base-md:			$footnotes-font-size-base-md + ($footnotes-font-size-base-md / 4);
$footnotes-line-height-base-lg:			$footnotes-font-size-base-lg + ($footnotes-font-size-base-lg / 4);
$footnotes-line-height-base-xl:			$footnotes-font-size-base-xl + ($footnotes-font-size-base-xl / 5);

$footnotes-padding-bottom:				$footnotes-font-size-base;
$footnotes-padding-bottom-xs:				$footnotes-font-size-base-xs;
$footnotes-padding-bottom-sm:				$footnotes-font-size-base-sm;
$footnotes-padding-bottom-md:				$footnotes-font-size-base-md;
$footnotes-padding-bottom-lg:				$footnotes-font-size-base-lg;
$footnotes-padding-bottom-xl:				$footnotes-font-size-base-xl;

$footnotes-font-weight:					normal;

// H1 TEXT SIZES & SPACING

$h1-font-size-base:					$p-font-size-base * 1.5;
$h1-font-size-base-xs:				$h1-font-size-base * 1.1;
$h1-font-size-base-sm:				$p-font-size-base-sm * 1.25;
$h1-font-size-base-md:				$p-font-size-base-md * 1.4;
$h1-font-size-base-lg:				$p-font-size-base-lg * 1.5;
$h1-font-size-base-xl:				$p-font-size-base-xl * 1.6;

$h1-line-height-base:				$h1-font-size-base * 1.2;
$h1-line-height-base-xs:				$h1-line-height-base;
$h1-line-height-base-sm:				$h1-font-size-base-sm * 1.25;
$h1-line-height-base-md:				$h1-font-size-base-md * 1.25;
$h1-line-height-base-lg:				$h1-font-size-base-lg * 1.25;
$h1-line-height-base-xl:				$h1-font-size-base-xl * 1.25;

$h1-padding-top:						$h1-font-size-base * 2;
$h1-padding-top-xs:					$h1-font-size-base-xs * 2;
$h1-padding-top-sm:					$h1-font-size-base-sm * 2;
$h1-padding-top-md:					$h1-font-size-base-md * 2;
$h1-padding-top-lg:					$h1-font-size-base-lg * 2;
$h1-padding-top-xl:					$h1-font-size-base-xl * 2;

$h1-padding-bottom:					$h1-font-size-base;
$h1-padding-bottom-xs:				$h1-font-size-base-xs;
$h1-padding-bottom-sm:				$h1-font-size-base-sm;
$h1-padding-bottom-md:				$h1-font-size-base-md;
$h1-padding-bottom-lg:				$h1-font-size-base-lg;
$h1-padding-bottom-xl:				$h1-font-size-base-xl;

$h1-font-weight:					bold;
$h1-font-style:						normal;
$h1-text-align:						left;
$h1-text-decoration:				none;
$h1-text-transform:					none;

// h2 TEXT SIZES & SPACING

$h2-font-size-base:					$p-font-size-base + 5px;
$h2-font-size-base-xs:				$h2-font-size-base;
$h2-font-size-base-sm:				$p-font-size-base-sm + 10px;
$h2-font-size-base-md:				$p-font-size-base-md + 10px;
$h2-font-size-base-lg:				$p-font-size-base-lg + 10px;
$h2-font-size-base-xl:				$p-font-size-base-xl + 10px;

$h2-line-height-base:				$h2-font-size-base * 1.7;
$h2-line-height-base-xs:				$h2-line-height-base;
$h2-line-height-base-sm:				$h2-font-size-base-sm * 1.7;
$h2-line-height-base-md:				$h2-font-size-base-md * 1.5;
$h2-line-height-base-lg:				$h2-font-size-base-lg * 1.5;
$h2-line-height-base-xl:				$h2-font-size-base-xl * 1.5;

$h2-padding-top:					$h2-font-size-base * 2;
$h2-padding-top-xs:					$h2-font-size-base-xs * 2;
$h2-padding-top-sm:					$h2-font-size-base-sm * 2;
$h2-padding-top-md:					$h2-font-size-base-md * 2;
$h2-padding-top-lg:					$h2-font-size-base-lg * 2;
$h2-padding-top-xl:					$h2-font-size-base-xl * 2;

$h2-padding-bottom:					$h2-font-size-base;
$h2-padding-bottom-xs:				$h2-font-size-base-xs;
$h2-padding-bottom-sm:				$h2-font-size-base-sm;
$h2-padding-bottom-md:				$h2-font-size-base-md;
$h2-padding-bottom-lg:				$h2-font-size-base-lg;
$h2-padding-bottom-xl:				$h2-font-size-base-xl;

$h2-font-weight:						bold;
$h2-font-style:						normal;
$h2-text-align:						left;
$h2-text-decoration:					none;
$h2-text-transform:					none;

// h3 TEXT SIZES & SPACING

$h3-font-size-base:					$p-font-size-base;
$h3-font-size-base-xs:				$h3-font-size-base;
$h3-font-size-base-sm:				$p-font-size-base-sm;
$h3-font-size-base-md:				$p-font-size-base-md;
$h3-font-size-base-lg:				$p-font-size-base-lg;
$h3-font-size-base-xl:				$p-font-size-base-xl;

$h3-line-height-base:				$h3-font-size-base + ($h3-font-size-base / 4);
$h3-line-height-base-xs:				$h3-line-height-base;
$h3-line-height-base-sm:				$h3-font-size-base-sm + ($h3-font-size-base-sm / 4);
$h3-line-height-base-md:				$h3-font-size-base-md + ($h3-font-size-base-md / 4);
$h3-line-height-base-lg:				$h3-font-size-base-lg + ($h3-font-size-base-lg / 4);
$h3-line-height-base-xl:				$h3-font-size-base-xl + ($h2-font-size-base-xl / 4);

$h3-padding-top:						$h3-font-size-base;
$h3-padding-top-xs:					$h3-font-size-base-xs;
$h3-padding-top-sm:					$h3-font-size-base-sm;
$h3-padding-top-md:					$h3-font-size-base-md;
$h3-padding-top-lg:					$h3-font-size-base-lg;
$h3-padding-top-xl:					$h3-font-size-base-xl;

$h3-padding-bottom:					$h3-font-size-base / 3;
$h3-padding-bottom-xs:				$h3-font-size-base-xs / 3;
$h3-padding-bottom-sm:				$h3-font-size-base-sm / 3;
$h3-padding-bottom-md:				$h3-font-size-base-md / 3;
$h3-padding-bottom-lg:				$h3-font-size-base-lg / 3;
$h3-padding-bottom-xl:				$h3-font-size-base-xl / 3;

$h3-font-weight:						bold;
$h3-font-style:						normal;
$h3-text-align:						left;
$h3-text-decoration:					none;
$h3-text-transform:					none;

// h4 TEXT SIZES & SPACING

$h4-font-size-base:					$p-font-size-base;
$h4-font-size-base-xs:				$h4-font-size-base;
$h4-font-size-base-sm:				$p-font-size-base-sm;
$h4-font-size-base-md:				$p-font-size-base-md;
$h4-font-size-base-lg:				$p-font-size-base-lg;
$h4-font-size-base-xl:				$p-font-size-base-xl;

$h4-line-height-base:				$h4-font-size-base + ($h4-font-size-base / 4);
$h4-line-height-base-xs:			$h4-line-height-base;
$h4-line-height-base-sm:			$h4-font-size-base-sm + ($h4-font-size-base-sm / 4);
$h4-line-height-base-md:			$h4-font-size-base-md + ($h4-font-size-base-md / 4);
$h4-line-height-base-lg:			$h4-font-size-base-lg + ($h4-font-size-base-lg / 4);
$h4-line-height-base-xl:			$h4-font-size-base-xl + ($h4-font-size-base-xl / 4);

$h4-padding-top:					$h4-font-size-base;
$h4-padding-top-xs:					$h4-font-size-base-xs;
$h4-padding-top-sm:					$h4-font-size-base-sm;
$h4-padding-top-md:					$h4-font-size-base-md;
$h4-padding-top-lg:					$h4-font-size-base-lg;
$h4-padding-top-xl:					$h4-font-size-base-xl;

$h4-padding-bottom:					$h4-font-size-base / 2;
$h4-padding-bottom-xs:				$h4-font-size-base-xs / 2;
$h4-padding-bottom-sm:				$h4-font-size-base-sm / 2;
$h4-padding-bottom-md:				$h4-font-size-base-md / 2;
$h4-padding-bottom-lg:				$h4-font-size-base-lg / 2;
$h4-padding-bottom-xl:				$h4-font-size-base-xl / 2;

$h4-font-weight:					bold;
$h4-font-style:						italic;
$h4-text-align:						left;
$h4-text-decoration:				none;
$h4-text-transform:					none;

// h5 TEXT SIZES & SPACING

$h5-font-size-base:					$p-font-size-base;
$h5-font-size-base-xs:				$h5-font-size-base;
$h5-font-size-base-sm:				$p-font-size-base-sm;
$h5-font-size-base-md:				$p-font-size-base-md;
$h5-font-size-base-lg:				$p-font-size-base-lg;
$h5-font-size-base-xl:				$p-font-size-base-xl;

$h5-line-height-base:				$h5-font-size-base + ($h5-font-size-base / 4);
$h5-line-height-base-xs:			$h5-line-height-base;
$h5-line-height-base-sm:			$h5-font-size-base-sm + ($h5-font-size-base-sm / 4);
$h5-line-height-base-md:			$h5-font-size-base-md + ($h5-font-size-base-md / 4);
$h5-line-height-base-lg:			$h5-font-size-base-lg + ($h5-font-size-base-lg / 4);
$h5-line-height-base-xl:			$h5-font-size-base-xl + ($h5-font-size-base-xl / 4);

$h5-padding-top:					$h5-font-size-base;
$h5-padding-top-xs:					$h5-font-size-base-xs;
$h5-padding-top-sm:					$h5-font-size-base-sm;
$h5-padding-top-md:					$h5-font-size-base-md;
$h5-padding-top-lg:					$h5-font-size-base-lg;
$h5-padding-top-xl:					$h5-font-size-base-xl;

$h5-padding-bottom:					$h5-font-size-base;
$h5-padding-bottom-xs:				$h5-font-size-base-xs;
$h5-padding-bottom-sm:				$h5-font-size-base-sm;
$h5-padding-bottom-md:				$h5-font-size-base-md;
$h5-padding-bottom-lg:				$h5-font-size-base-lg;
$h5-padding-bottom-xl:				$h5-font-size-base-xl;

$h5-font-weight:					bold;