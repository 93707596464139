// Grid containers

.container {
	
	display: 			block;
	position: 			relative;
	
	padding-left: 		$container-padding;
	padding-right: 		$container-padding;
	padding-top: 		0;
	padding-bottom: 	0;
	
	width: 100%;
		
	margin-left: auto;
	margin-right: auto;
		
	@include sm {
		max-width: $container-sm !important;
	}
	
	@include md {
		max-width: $container-md !important;
	}
	
	@include lg {
		max-width: $container-lg !important;
	}
	
	@include xl {
		max-width: $container-xl !important;
	}

}

@mixin has-notch {
	@supports(padding: max(0px)) {
		// iOS 11.0 - 11.1
		padding-top: unquote('max(0px, constant(safe-area-inset-top))');
		padding-bottom: unquote('max(0px, constant(safe-area-inset-bottom))');
		padding-left: unquote('max(0px, constant(safe-area-inset-left))');
		padding-right: unquote('max(0px, constant(safe-area-inset-right))');
		// iOS 11.2
		padding-top: unquote('max(0px, env(safe-area-inset-top))');
		padding-bottom: unquote('max(0px, env(safe-area-inset-bottom))');
		padding-left: unquote('max(0px, env(safe-area-inset-left))');
		padding-right: unquote('max(0px, env(safe-area-inset-right))');
	}
}
