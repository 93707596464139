@at-root {
	@-ms-viewport { 
		width: device-width;
	} // stylelint-disable-line at-rule-no-vendor-prefix
}

*,
*::before,
*::after {
	box-sizing: border-box;
	
	-webkit-font-smoothing: antialiased;
	letter-spacing: normal;
  
	-webkit-appearance: none;
	-webkit-border-radius: 0;
}

html {
  -ms-overflow-style: scrollbar;
	background: 		$black;
}

html,
body {
	background:			$black;
	
	font-family: 		$font-family-base;
	
	padding: 0;
	margin: 0;
}

main {
	background: $white;
}