header#site-title {

    display: 		block;
    position: 		relative;
    
    background:		$yellow;
    
	padding: 		30px 0;
	
	@include md-up {
		padding: 	60px 0;
	}
    width: 			100%;
		
	a#header--site-logo {
		
		position: 	relative;		
		display: 	inline-block;
	
		color: $black;

		&:hover,
		&:focus {
			color: 					$black;
			background: 			none;
			
			text-decoration-skip: 	ink;
			text-decoration-skip-ink: auto;
	
			outline: none;

			h1 {
				text-decoration: 		underline !important;
			}
		}

		img.header--site-logo--logo {
			
			position: 		relative;
			display: 		inline-block;
			
			@include md-down {
				max-height: 	60px;
				margin-right: 	13px;
			}
			
			@include lg-up {
				max-height: 	100px;
				margin-right: 	25px;
			}
			
			float:			left;
		}
		
		h1.header--site-logo--title {

			position: 		relative;
			display: 		inline;
			
			padding: 		0;
			
			@include md-down {
				margin: 		-10px 0 0 0;
			
				font-size: 		30px;
				line-height: 	40px;	
			}
			
			@include lg-up {
				margin: 		-13px 0 0 0;
			
				font-size: 		55px;
				line-height: 	60px;	
			}
			
			float:			left;

			span.header--site-title--newline {
				display: block;
			}

		}
		
    }

}