// Only display content to screen readers
//
// See: https://a11yproject.com/posts/how-to-hide-content/
// See: https://hugogiraudel.com/2016/10/13/css-hide-and-seek/

@mixin sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

// Use in conjunction with .sr-only to only display content when it's focused.
//
// Useful for "Skip to main content" links; see https://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
//
// Credit: HTML5 Boilerplate

@mixin sr-only-focusable {
  &:active,
  &:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
}

.sr-only {
  @include sr-only;
}

.sr-only-focusable {
  @include sr-only-focusable;
}

.skip-link {
  @include sr-only;
  @include sr-only-focusable;
  
  display: block;
  width: 100%;
  padding: 10px;
  
  text-align: 			center;
  
  &:focus,
  &:hover {
	  background: 		$black;
	  color:			$yellow;
  }
}